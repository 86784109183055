import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Main from '../screens/Main';
import Auth from '../screens/Auth';
import Gpt from '../screens/Gpt';
import O1 from '../screens/O1';
import Tts from '../screens/Tts';
import Whisper from '../screens/Whisper';
import Dalle from '../screens/Dall-e';
import Elevenmultilingual from '../screens/Elevenmultilingual';
import Eleventurbo from '../screens/Eleventurbo';
import Soundgeneration from '../screens/Soundgeneration';

const Navigation: React.FC = () => {
return (
<Router>
<Routes>
<Route path="/" element={<Main />} />
<Route path="/auth" element={<Auth title="api key 등록" />} />
<Route path="/chat" element={<Gpt title="챗봇" roomType="chat" />} />
<Route path='/specialfriend' element={<Gpt title="가상 연인" roomType="specialfriend" />} />
<Route path='/translation' element={<Gpt title="번역" roomType="translation" />} />
<Route path='/englishteacher' element={<Gpt title="영어 공부" roomType="englishteacher" />} />
<Route path='/frenchteacher' element={<Gpt title="프랑스어 공부" roomType="frenchteacher" />} />
<Route path='/cooking' element={<Gpt title="요리 레시피" roomType="cooking" />} />
<Route path='/summingup' element={<Gpt title="텍스트 요약" roomType="summingup" />} />
<Route path='/summingupdoc' element={<Gpt title="문서 요약" roomType="summingupdoc" />} />
<Route path='/subject' element={<Gpt title="주제 생성" roomType="subject" />} />
<Route path='/mbti' element={<Gpt title="MBTI 성격 유형 검사" roomType="mbti" />} />
<Route path='/businessmail' element={<Gpt title="비즈니스 메일 작성" roomType="businessmail" />} />
<Route path='/decision' element={<Gpt title="결정 봇" roomType="decision" />} />

<Route path="/chato1" element={<O1 title="챗봇" roomType="chato1" />} />

<Route path='/ttsdownload' element={<Tts title="텍스트 음성 변환후 다운로드" roomType="ttsdownload" />} />
<Route path='/ttsplay' element={<Tts title="텍스트 음성 변환후 재생" roomType="ttsplay" />} />

<Route path='/transcript' element={<Whisper title="음성 텍스트 변환" roomType="transcript" />} />
<Route path='/englishtranslation' element={<Whisper title="음성 영문 번역" roomType="englishtranslation" />} />

<Route path='/imagecreation' element={<Dalle title="이미지 생성" roomType="imagecreation" />} />
<Route path='/imagevariation' element={<Dalle title="이미지 변형" roomType="imagevariation" />} />

<Route path='/labsdownload' element={<Elevenmultilingual title="텍스트 음성 변환후 다운로드" roomType="labsdownload" />} />
<Route path='/labsplay' element={<Elevenmultilingual title="텍스트 음성 변환후 재생" roomType="labsplay" />} />

<Route path='/labsdownloadbeta' element={<Eleventurbo title="텍스트 음성 변환후 다운로드(베타)" roomType="labsdownloadbeta" />} />
<Route path='/labsplaybeta' element={<Eleventurbo title="텍스트 음성 변환후 재생(베타)" roomType="labsplaybeta" />} />

<Route path='/sounddownload' element={<Soundgeneration title="사운드 생성후 다운로드" roomType="sounddownload" />} />
<Route path='/soundplay' element={<Soundgeneration title="텍스트 사운드 생성후 재생" roomType="soundplay" />} />
</Routes>
</Router>
);
}

export default Navigation;
